import { LocalStorage } from '@/enums/localStorage';
import { Region } from '@/types/region';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

type State = {
	countries: Region[];
	selectedCountry: Region;
	setCountries: (regions: undefined | Region[]) => void;
	setSelectedCountry: (region: Region) => void;
};

export const useRegionsStore = create<State>()(
	persist(
		(set) => ({
			countries: [],
			selectedCountry: {
				id: -1,
				names: [
					{ id: 4083, is_ltr: false, text: 'اختر الدولة', lang: 'ar', lang_type_display: 'ar' },
					{ id: 4084, is_ltr: true, text: 'Choose country', lang: 'en', lang_type_display: 'en' }
				],
				shipment_price: 0.0,
				minimum_order_price: 0.0,
				display: true,
				country_code: null,
				currency_code: null,
				parent_region: null
			},
			setCountries: (regions) => {
				set((state) => ({ countries: regions ? regions : [] }));
			},
			setSelectedCountry: (region) => {
				set((state) => ({ ...state, selectedCountry: region }));
			}
		}),
		{
			name: LocalStorage.COUNTRY,
			partialize: (state) => ({ selectedCountry: state.selectedCountry }),
			storage: createJSONStorage(() => sessionStorage)
		}
	)
);
