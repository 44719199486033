import { Domain } from '@/types/domain';
import { create } from 'zustand';

type State = {
	domain: Partial<Domain>;
	setDomain: (domain: Domain) => void;
};

export const useDomainStore = create<State>((set) => ({
	domain: {},
	setDomain(domain) {
		set((state) => ({ domain }));
	}
}));
