import { Endpoints } from '@/enums/endpoints';
import { QueryOptions } from '@/types/queryOptions';
import { RequestError } from '@/types/requestError';
import { Responses } from '@/types/responses';
import { getErrorNames } from '@/utilities/getErrorName';
import createAxiosInstance from '@/utilities/http';
import { QueryClient, QueryFunctionContext, useQuery } from '@tanstack/react-query';

export const listing = async (context: QueryFunctionContext) => {
	const { baseUrl, limit, accessToken } = context.queryKey[1] as QueryOptions;

	const HTTP = createAxiosInstance({ baseUrl, accessToken });

	let url: string = Endpoints.PAGE_ICONS;

	url += `?limit=${limit || 10}`;

	try {
		const { data } = await HTTP.get(url);
		return data;
	} catch (error: any) {
		throw Error(getErrorNames(error)[0], { cause: 'Error fetching page icons' });
	}
};

export const pageIconsPrefetching = (queryClient: QueryClient, options: QueryOptions) => {
	return queryClient.prefetchQuery<Responses.Pages.IconsListing, RequestError>([Endpoints.PAGE_ICONS, options], listing);
};

export const usePageIconsListing = (options: QueryOptions) => {
	return useQuery<Responses.Pages.IconsListing, RequestError>([Endpoints.PAGE_ICONS, options], listing);
};
