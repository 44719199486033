import { Endpoints } from '@/enums/endpoints';
import { QueryOptions } from '@/types/queryOptions';
import { RequestError } from '@/types/requestError';
import { Responses } from '@/types/responses';
import { getErrorNames } from '@/utilities/getErrorName';
import createAxiosInstance from '@/utilities/http';
import { QueryClient, QueryFunctionContext, useQuery } from '@tanstack/react-query';

export const listing = async (context: QueryFunctionContext) => {
	const { baseUrl, accessToken, getCountries, parent_region } = context.queryKey[1] as QueryOptions;

	const HTTP = createAxiosInstance({ baseUrl, accessToken });

	let url: string = `${Endpoints.USERS_REGION}`;

	if (getCountries) url = url + '?limit=150&is_country=true';
	if (parent_region) url = url + `?limit=250&parent_region=${parent_region}`;

	try {
		const { data } = await HTTP.get(url);
		return data;
	} catch (error: any) {
		throw Error(getErrorNames(error)[0], { cause: 'Error fetching regions' });
	}
};

export const regionsPrefetching = (queryClient: QueryClient, options: QueryOptions) => {
	return queryClient.prefetchQuery<Responses.Users.RegionsListing, RequestError>([Endpoints.USERS_REGION, options], listing);
};

export const useRegionsListing = (options: QueryOptions) => {
	return useQuery<Responses.Users.RegionsListing, RequestError>([Endpoints.USERS_REGION, options], listing);
};
