export enum Endpoints {
	// Domains
	DOMAINS = '/domains/domain-details-guest/',

	// Pages
	PAGES = '/pages/get/',
	PAGE_ICONS = '/pages/icons/',

	// Blog
	BLOG = '/blog/',
	BLOG_SUBSCRIBE = '/blog/subscribe/',

	// Category
	CATEGORY = '/category/',
	CATEGORY_MENU = '/category/menu/',

	// Carousel
	CAROUSEL = '/carousel/',

	// Products
	PRODUCTS = '/products/',
	PRODUCTS_CART = '/products/cart/',
	PRODUCTS_WISH = '/products/wish/',
	PRODUCTS_COUPONS = '/products/coupons/',
	PRODUCTS_ATTRIBUTES = '/products/attribute/',
	PRODUCTS_DESCRIPTIONS = '/products/descriptions/',
	PRODUCTS_ATTRIBUTE_COLLECTION = '/products/attribute/collection/',

	// Orders
	ORDERS = '/orders/',
	ORDERS_GUEST = '/orders/guest/',
	ORDERS_ORDER = '/orders/order/',
	ORDERS_CANCEL = '/orders/cancel/',
	ORDERS_GUEST_TRACK = '/orders/guest/track/',
	ORDERS_DISCOUNT_APPLY = '/orders/apply/discount/',

	// Payment
	PAYMENT_WEBHOOKS = '/payment/webhooks/',
	PAYMENT_FATOORAH = '/payment/fatoorah/',
	PAYMENT_TABBY = '/payment/tabby/',
	PAYMENT_TAMARA = '/payment/tamara/',
	PAYMENT_PAYMOB = '/payment/paymob/',
	PAYMENT_STRIPE = '/payment/stripe/',

	// Rating
	RATING_REPLY = '/rating/reply/',
	RATING_PRODUCT = '/rating/product/',
	RATING_LIKE = '/rating/likes/',

	// Users
	USERS_LOGIN = '/user/auth/login/',
	USERS_REGISTER = '/user/auth/signup/',
	USERS_UPDATE = '/user/utils/update/',
	USERS_IS_LOGGED_IN = '/user/auth/is/logged/',
	USERS_ACTIVATE = '/user/auth/activate/',
	USERS_PASSWORD = '/user/auth/password/',
	USERS_SHIPMENT = '/user/shipment/',
	USERS_PHONES_LIST = '/user/utils/phone/',
	USERS_EMAIL_VERIFY = '/user/utils/email/verify/',
	USERS_REGION = '/user/regions/',
	USERS_CONTACT = '/user/utils/contact/',
	USERS_PHONE_CREATE = '/user/utils/phone/',

	// Gateways
	GATEWAYS_ACTIVATED = '/gateway/activated/',
	GATEWAYS_CURRENCY = '/gateway/currency/',
	GATEWAYS_FACTORS = '/gateway/factors/'
}
