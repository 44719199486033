import { QueryParams } from '@/types/queryOptions';
import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';

const createAxiosInstance = ({ baseUrl, accessToken }: QueryParams): AxiosInstance => {
	const instance: AxiosInstance = axios.create({ baseURL: `https://${baseUrl}`, timeout: 30000 });

	instance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
		if (accessToken) config.headers!.Authorization = `Bearer ${accessToken}`;
		return config;
	});

	return instance;
};

export default createAxiosInstance;
