import { RequestError } from '@/types/requestError';

export const getErrorNames = (error: RequestError): string[] => {
	const data = error.response?.data!;

	if (!data || !Array.isArray(data) || data.length === 0) return ['something_wrong'];

	const errors: string[] = [];

	const keys = data.map((error) => Object.keys(error));
	const values = data.map((error) => Object.values(error));

	for (let i = 0; i < keys.length; i++) {
		for (let j = 0; j < keys[i].length; j++) {
			errors.push(`${keys[i][j]}_${values[i][j]}`.toLowerCase());
		}
	}

	return errors;
};
