import { Endpoints } from '@/enums/endpoints';
import { QueryOptions } from '@/types/queryOptions';
import { RequestError } from '@/types/requestError';
import { Responses } from '@/types/responses';
import { getErrorNames } from '@/utilities/getErrorName';
import createAxiosInstance from '@/utilities/http';
import { QueryClient, QueryFunctionContext, useQuery } from '@tanstack/react-query';

export const listing = async (context: QueryFunctionContext) => {
	const { baseUrl, accessToken } = context.queryKey[1] as QueryOptions;

	const HTTP = createAxiosInstance({ baseUrl, accessToken });

	let url: string = Endpoints.GATEWAYS_FACTORS;

	try {
		const { data } = await HTTP.get(url);
		return data;
	} catch (error: any) {
		throw Error(getErrorNames(error)[0], { cause: 'Error fetching currency factors' });
	}
};

export const factorsPrefetching = (queryClient: QueryClient, options: QueryOptions) => {
	return queryClient.prefetchQuery<Responses.Factors.Listing, RequestError>([Endpoints.GATEWAYS_FACTORS, options], listing);
};

export const useFactorsListing = (options: QueryOptions) => {
	return useQuery<Responses.Factors.Listing, RequestError>([Endpoints.GATEWAYS_FACTORS, options], listing);
};
