import { Cookies } from '@/enums/cookies';
import { Routes } from '@/enums/routes';
import { carouselsPrefetching } from '@/services/carousels/get/listing';
import { categoriesPrefetching } from '@/services/categories/index/get/listing';
import { menusPrefetching } from '@/services/categories/menus/get/listing';
import { factorsPrefetching, useFactorsListing } from '@/services/gateways/factors/get/listing';
import { pageIconsPrefetching, usePageIconsListing } from '@/services/pages/icons/get/listing';
import { productsPrefetching } from '@/services/products/index/get/listing';
import { regionsPrefetching, useRegionsListing } from '@/services/users/regions/get/listing';
import { useAuthStore } from '@/stores/auth';
import { useCurrencyStore } from '@/stores/currency';
import { useDomainStore } from '@/stores/domain';
import { useRegionsStore } from '@/stores/regions';
import { AppThemeData } from '@/types/appTheme';
import { Domain } from '@/types/domain';
import { Session } from '@/types/session';
import { getColorPalette } from '@/utilities/colors';
import { getDomain } from '@/utilities/domains';
import { getThemeByName } from '@/utilities/theme';
import { QueryClient, dehydrate } from '@tanstack/react-query';
import { CookieValueTypes, getCookie, setCookie } from 'cookies-next';
import { GetServerSideProps, NextPage } from 'next';
import loadNamespaces from 'next-translate/loadNamespaces';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

const PageContent = dynamic(() => import('@/components/PageContent'));

type Props = {
	domain: Domain;
	session: Session | null;
};

export const getServerSideProps: GetServerSideProps = async ({ req, res, locale }) => {
	const queryClient = new QueryClient();

	const domainCookie: CookieValueTypes = getCookie(Cookies.DOMAIN, { req, res });
	const sessionCookie: CookieValueTypes = getCookie(Cookies.SESSION, { req, res });

	const domain: Domain | null = await getDomain({ domainCookie, queryClient, req });
	const session: Session | null = sessionCookie ? JSON.parse(sessionCookie as string) : null;

	if (!domain) throw Error('Domain unavailable');
	else setCookie(Cookies.DOMAIN, JSON.stringify(domain), { req, res });

	const requests: Promise<void>[] = [
		factorsPrefetching(queryClient, { baseUrl: domain.backend_url, accessToken: session?.access }),
		regionsPrefetching(queryClient, { baseUrl: domain.backend_url, accessToken: session?.access, getCountries: true }),
		pageIconsPrefetching(queryClient, { baseUrl: domain.backend_url, accessToken: session?.access }),
		categoriesPrefetching(queryClient, { baseUrl: domain.backend_url, accessToken: session?.access }),
		productsPrefetching(queryClient, { baseUrl: domain.backend_url, accessToken: session?.access }),
		carouselsPrefetching(queryClient, { baseUrl: domain.backend_url, accessToken: session?.access }),
		menusPrefetching(queryClient, { baseUrl: domain.backend_url, accessToken: session?.access })
	];

	await Promise.all(requests);

	const dehydratedState = JSON.parse(JSON.stringify(dehydrate(queryClient)));
	const translations = await loadNamespaces({
		locale,
		pathname: '/',
		loadLocaleFrom: (lang, ns) => import(`@/templates/${domain.theme.name}/locales/${lang}/${ns}.json`).then((m) => m.default)
	});

	return {
		props: { session, domain, dehydratedState, ...translations }
	};
};

const Home: NextPage<Props> = ({ domain, session }) => {
	const [themeData, setThemeData] = useState<AppThemeData | null>(null);

	const { locale } = useRouter();

	const { setDomain } = useDomainStore.getState();
	const { setSession } = useAuthStore.getState();
	const { setFactors } = useCurrencyStore.getState();
	const { setCountries } = useRegionsStore.getState();

	const { data: factors } = useFactorsListing({ baseUrl: domain.backend_url, accessToken: session?.access });
	const { data: countries } = useRegionsListing({ baseUrl: domain.backend_url, accessToken: session?.access, getCountries: true });
	const { data: icons } = usePageIconsListing({ baseUrl: domain.backend_url, accessToken: session?.access });

	const themeName = domain.theme.name.toLowerCase();
	const palette = getColorPalette(domain);

	useEffect(() => {
		const initializeThemeData = async (name: string) => {
			const theme = await getThemeByName(name);
			setThemeData(theme(palette, locale));
		};

		if (themeName) initializeThemeData(themeName);
	}, [themeName]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setDomain({ ...domain, links: icons?.results });
	}, [domain]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setSession(session);
	}, [session]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setFactors(factors);
	}, [factors]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setCountries(countries?.results);
	}, [countries?.results]); // eslint-disable-line react-hooks/exhaustive-deps

	return themeName && themeData && <PageContent themeName={themeName} themeData={themeData} slug={Routes.ROOT} />;
};

export default Home;
