import { CurrencyFactor } from '@/types/currency';
import { create } from 'zustand';

type State = {
	factors: CurrencyFactor[];
	setFactors: (factors: undefined | CurrencyFactor[]) => void;
};

export const useCurrencyStore = create<State>()((set) => ({
	factors: [],
	setFactors: (factors: undefined | CurrencyFactor[]) => {
		set((state) => ({ factors: factors ? factors : [] }));
	}
}));
